import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Link from 'next/link';
import Parallax from "components/Parallax/Parallax.js";
import classNames from "classnames";
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
import MyStorage from 'utils/mystorage'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import dynamic from 'next/dynamic'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

const HeaderLinks = dynamic(() => import("components/Header/HeaderLinks.js"))
const Header = dynamic(() => import("components/Header/Header.js"))
const Footer = dynamic(() => import('components/Footer/Footer.js'))
const ProductSection = dynamic(() => import('pages-sections/LandingPage-Sections/ProductSection.js'))
const WorkSection = dynamic(() => import('pages-sections/LandingPage-Sections/WorkSection.js'))
const MySuccess = dynamic(() => import('components/My/MySuccess'))
const SettingsIcon = dynamic(() => import('@material-ui/icons/Settings'))
const FeaturesSection = dynamic(() => import('pages-sections/LandingPage-Sections/FeaturesSection.js'))
const FAQSection = dynamic(() => import('pages-sections/LandingPage-Sections/FAQSection.js'))
const TutorialsSection = dynamic(() => import('components/My/Tutorials/TutorialIndexPage'))
const TestimonialSection = dynamic(() => import('pages-sections/LandingPage-Sections/TestimonialSection.js'))
const MostImportantFeatures = dynamic(() => import('pages-sections/LandingPage-Sections/MostImportantFeaturesSection.js'))

const useStyles = makeStyles(styles);

const ClaimSection = ({classes}) => {
  return (
    <Box align="center" mt={5}>
      <Link href="/login">
        <Button color="danger" size="lg" rel="noopener noreferrer" style={{borderRadius: '1.2em'}}>
          <ArrowForwardIcon className={classes.icons} /> Become an instructor
        </Button>
      </Link>
    </Box>
  )
}

function Index(props) {

  const { query, domainInfo, ...rest } = props
  const classes = useStyles()

  var myCache = new MyStorage()
  const [ user, setUser ] = useState(myCache.get('user') ? myCache.get('user') : false)

  useEffect(() => {
  }, [domainInfo, user])

  if(props.domainInfo.domainName == "www") 
  return (
    <div>
        <Header
          color="transparent"
          brand="My Love - Fitness"
          rightLinks={<HeaderLinks {...props} user={user}/>}
          fixed
          changeColorOnScroll={{
            height: 10,
            color: "white"
          }}
          user={user}
          {...props} 
        />
      <Parallax filter responsive image={domainInfo?.bgMain ? domainInfo.bgMain : require("assets/img/bg8.jpg")} video={domainInfo?.ytindex}>
        <div className={classes.container} style={{backgroundColor: '#00000066', borderRadius: '30px', marginTop: '10em', textAlign: 'justify', padding: "0px 0px 10px 25px"}}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box width={'100%'} style={{paddingRight: '1em', paddingLeft: '1em'}}>
                  <h2 className={classes.title}>You focus on teaching. We do the rest.</h2>
                  <Typography variant="h5" color="initial" style={{ lineSpacing: '45px', letterSpacing: '0.6px'}}>
                    All-in-one solution for online classes.
                  </Typography>
                </Box>              
              </Grid>
            </Grid>
              <br/>
                  <Link href={(user) ? "/my/reserve" : "/login"}>
                    <Button
                      color="danger"
                      size="lg"
                      rel="noopener noreferrer"
                      style={{borderRadius: '1.2em'}}
                    >
                      <ArrowForwardIcon className={classes.icons} />
                    Become an instructor
                    </Button>
                  </Link>
                  <br/><br/>
        </div>
      </Parallax>
      <div style={{ marginTop: "0.5vh"}} className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {/* <Typography variant="h1" color="initial"></Typography> */}
          <Box style={{textAlign: 'justify', padding: '55px 20px 37px 20px'}}>
            <Typography variant="h4" color="textPrimary" align="center">
                What we do
            </Typography>
            <Typography variant="h5" color="textPrimary">
                We've built a platform to create your unique website, manage your schedule, memberships, payments, and more without you worrying about any technical aspect.
            </Typography>
          </Box>

            <MostImportantFeatures {...props} user={user} />

            <FeaturesSection {...props} user={user}/>

            <ClaimSection classes={classes} />

            <TutorialsSection {...props} user={user}/>

            <ClaimSection classes={classes} />
            <br/>

            <FAQSection {...props} user={user}/>

            <ClaimSection classes={classes} />
            <br/>

            <WorkSection {...props} user={user} />
        </div>
      </div>
        <Footer {...props} user={user}/>
    </div>
  )

  return (
    <div>
        <Header
          color="transparent"
          brand="My Love - Fitness"
          rightLinks={<HeaderLinks {...props} user={user}/>}
          fixed
          changeColorOnScroll={{
            height: 10,
            color: "white"
          }}
          user={user}
          {...props} 
        />
      <Parallax filter responsive image={domainInfo?.bgMain ? domainInfo.bgMain : require("assets/img/bg8.jpg")} video={domainInfo?.ytindex}>
        <div className={classes.container} style={{backgroundColor: '#00000033', borderRadius: '30px', padding: '1em', marginTop: '15vh', width: '95%'}}>
            <Typography variant="h3">
                {domainInfo?.welcomeheadline ? (<>{domainInfo.welcomeheadline}</>) : ("Your Fitness Class Starts Today.")}
            </Typography>
            <br/>
            <Typography variant="h5">
                {domainInfo?.welcomeline ? (<>{domainInfo.welcomeline}</>) : ("Welcome to probably the best online platform that will make you fit.")}
            </Typography>
              <br />
                  { (user) ? (
                      <MySuccess {...props} user={user}/>
                  ) : (
                    <>
                      <Link href="/login">
                        <Button
                          color="danger"
                          size="lg"
                          rel="noopener noreferrer"
                        >
                          <SettingsIcon className={classes.icons} />
                        Register now - it's free.
                        </Button>
                      </Link>
                      <br/><br/>
                    </>
                  )}
        </div>
      </Parallax>
      <div style={{ marginTop: "0.5vh"}} className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
            <ProductSection {...props} user={user} instructor={true}/>
            <TestimonialSection {...props} user={user} instructor={true}/>
            <WorkSection {...props} user={user} instructor={true}/>
        </div>
      </div>
        <Footer {...props} user={user}/>
    </div>
  )
}

export default Index
